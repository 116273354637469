import http from './http'

export const find = (id) => {
  return http.get('students/find', {
    params: {id}
  })
}

export const programs = (id) => {
  return http.get('students/programs', {
    params: {id}
  })
}

export const attendances = (id) => {
  return http.get('students/attendances', {
    params: {id}
  })
}

export const reports = (id) => {
  return http.get('students/reports', {
    params: {id}
  })
}

export const files = (id) => {
  return http.get('students/files', {
    params: {id}
  })
}

export const save = (data) => {
  return http.post('students/save', {
    id: data.id,
    name: data.name,
    avatar: data.avatar,
    birthday: data.birthday,
    gender: data.gender,
    comments: data.comments,
    active: data.active,
  })
}

export const finish = (data) => {
  return http.put('students/finish', {
    id: data.id
  })
}

export const maintenance = (data) => {
  return http.put('students/maintenance', {
    id: data.id
  })
}

const view = (id) => {
  return http.get('students/view', {
    params: {id}
  })
}

const create = (data) => {
  return http.post('students/create', data)
}

const update = (data) => {
  return http.post('students/update', data)
}

const remove = (data) => {
  return http.post('students/delete', data)
}

export default {
  find,
  view,
  create,
  update,
  remove,
  programs,
  attendances,
  reports,
  files,
  save,
  finish,
  maintenance,
}