export default () => ({
  id: '',
  name: '',
  avatar: {
    name: '',
    path: '',
  },
  gender: '',
  birthday: '',
  comments: '',
  active: true,
  companions: [],
})