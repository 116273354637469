<template>
  <v-container>
    <v-loading v-model="loading">
        <v-list-item class="px-2">
        <v-list-item-content>
          <v-list-item-title class="title">
            Estudantes
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn fab depressed small color="primary" @click="onCreate">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-card outlined>
        <v-simple-table>
          <thead>
            <tr class="text-uppercase">
              <th colspan="100">Nome</th>
            </tr>  
          </thead>    
          <tbody>
            <template v-for="student in students">
              <tr :key="student.id">
                <td>{{ student.name }}</td>
                <td class="text-right">
                  <v-btn icon @click="onEdit(student)">
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                  <v-btn icon :loading="deleting === student.id" :disabled="deleting === student.id" @click="onDelete(student)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-loading>
    <EditStudentDialog
      :student="student"
      v-model="editStudentDialog"
    />
    <CreateStudentDialog
      v-model="createStudentDialog"
    />
  </v-container>
</template>

<script>
  import UserService from '../../services/user';
  import StudentService from '../../services/student';
  import EditStudentDialog from '../../dialogs/EditStudentDialog';
  import CreateStudentDialog from '../../dialogs/CreateStudentDialog';

  export default {
    components: {
      EditStudentDialog,
      CreateStudentDialog
    },
    data: () => ({
      loading: false,
      deleting: null,
      editStudentDialog: false,
      createStudentDialog: false,
      student: {},
      students: [],
    }),
    watch: {
      editStudentDialog(value) {
        if (!value) {
          this.fetchStart()
        }
      },
      createStudentDialog(value) {
        if (!value) {
          this.fetchStart()
        }
      }
    },
    methods: {
      onEdit(student) {
        this.student = student
        this.editStudentDialog = true
      },
      onCreate() {
        this.createStudentDialog = true
      },
      onDelete(student) {
        if (confirm('Deseja realmente remover este Estudante?')) {  
          this.deleting = student.id;
          StudentService.remove({
            id: student.id,
          }).then(() => {
            this.fetchStart();
          }).catch(() => alert('Não foi possível remover o Estudante!'))
          .then(() => {
            this.deleting = null;    
          });
        }
      },
      fetchStart() {
        this.loading = true

        UserService.students()
          .then((data) => {
            this.students = data
            this.loading = false
          })
      }
    },
    mounted() {
      this.fetchStart()
    }
  }
</script>
