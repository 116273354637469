<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-form @submit.prevent="onSubmit">
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Estudante</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-loading v-model="loading">
          <v-card-text>
            <v-container fluid class="px-0">
              <v-row align="center">
                <v-col cols="auto">
                  <v-avatar size="60" color="grey">
                    <template v-if="form.avatar.path">
                      <v-img :src="form.avatar.path"/>
                    </template>
                    <template v-else>
                      <span class="white--text text-uppercase" :style="{fontSize: '30px'}">
                        {{ initials }}
                      </span>
                    </template>
                  </v-avatar>
                </v-col>
                <v-col cols="auto">
                  <AvatarUploader @success="onAvatarUploadSuccess">
                    Alterar Foto
                  </AvatarUploader>
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col cols="12">
                  <v-card outlined>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mb-3">Código pessoal para vinculação de cadastro.</v-list-item-subtitle>
                        <v-list-item-title class="headline">
                          <v-row align="center" justify="space-between">
                            <v-col cols="auto">
                              {{ form.code }}
                            </v-col>
                            <v-col cols="auto">
                              <v-btn icon x-small type="button" v-clipboard:copy="form.code" v-clipboard:success="onCopy">
                                <v-icon>mdi-content-copy</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col> -->
                <v-col cols="12">
                  <v-checkbox hide-details class="pt-0 mt-0" label="Habilitado" v-model="form.active"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details label="Nome" v-model="form.name"/>
                </v-col>
                <v-col cols="12">
                  <v-select dense outlined hide-details label="Gênero" :items="genderLists" v-model="form.gender"/>
                </v-col>
                <v-col cols="12">
                  <v-dialog persistent width="290px" v-model="birthdayDialog">
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field dense outlined hide-details readonly append-icon="mdi-calendar-month-outline" label="Data de Nascimento" v-model="birthdayFormated" v-bind="attrs" v-on="on"/>
                    </template>
                    <v-date-picker scrollable locale="pt-br" v-model="form.birthday">
                      <v-spacer/>
                      <v-btn text color="primary" @click="birthdayDialog = false">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-textarea dense outlined hide-details type="text" label="Comentários" v-model="form.comments"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <h3>Acompanhantes</h3>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details label="Filtrar acompanhante" v-model="companionFilter"/>
                </v-col>
                <v-col>
                  <v-card outlined max-height="250">
                    <v-flex class="overflow-auto" :style="{maxHeight: '210px'}">
                      <v-list dense class="pa-0">
                        <template v-for="companion in companionsFiltered">
                          <v-list-item :key="companion.id">
                            <v-list-item-action>
                              <v-checkbox :value="companion" v-model="form.companions"/>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{ companion.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider :key="`divider-${companion.id}`"/>
                        </template>
                      </v-list>
                    </v-flex>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="tertiary">
            <v-btn text @click="onClose">Fechar</v-btn>
            <v-spacer/>
            <v-btn depressed :disabled="saving" :loading="saving" type="submit" color="primary">Salvar</v-btn>
          </v-card-actions>
        </v-loading>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import dayjs from 'dayjs'
  import UserService from '../services/user'
  import StudentService from '../services/student'
  import AvatarUploader from '../components/AvatarUploader'
  import studentFormModel from '../models/StudentForm'
  import genderOptions from '../models/GenderOptions'

  export default {
    props: ['value', 'student'],
    components: {
      AvatarUploader,
    },
    data: () => ({
      saving: false,
      loading: false,
      showPassword: false,
      birthdayDialog: false,
      companionFilter: null,
      errors: {},
      companions: [],
      genderLists: genderOptions(),
      form: studentFormModel(),
    }),
    watch: {
      value(value) {
        if (value) {
          this.mountData()
        }
      }
    },
    methods: {
      mountData() {
        this.errors = []
        this.form = studentFormModel()
        this.fetchInitial()
      },
      fetchInitial() {
        this.loading = true

        Promise.all([
          UserService.companions()
            .then(data => {
              this.companions = data
            }),
          StudentService.view(this.student.id)
            .then(data => {
              this.form = {
                ...this.form,
                ...data
              }
            }),
        ]).then(() => this.loading = false)
      },
      onClose() {
        this.$emit('input', false)
      },
      onCopy() {
        this.$root.$emit('snackbar', {
          text: 'Código copiado.',
          color: 'green',
        })
      },
      onSubmit() {
        this.saving = true

        StudentService.update(this.form)
          .then(() => {
            this.$root.$emit('snackbar', {
              text: 'Dados salvos com sucesso.',
              color: 'green',
            })
            this.onClose()
          })
          .catch(data => {
            this.$root.$emit('snackbar', {
              text: 'Não foi possível salvar os dados.',
              color: 'red',
            })
            this.errors = data.errors || {}
          })
          .then(() => this.saving = false)
      },
      onAvatarUploadSuccess(data) {
        this.form.avatar = data
      },
    },
    computed: {
      birthdayFormated() {
        return this.form.birthday && dayjs(this.form.birthday).format('DD/MM/YYYY')
      },
      initials() {
        const name = this.form.name || ''
        const parts = name.trim().split(' ')

        if (parts.length === 1) {
          return name.substr(0, 2)
        }

        return (parts[0][0] || '') + (parts[1][0] || '')
      },
      iconPassword() {
        return this.showPassword ? 'mdi-eye-off' : 'mdi-eye'
      },
      typePassword() {
        return this.showPassword ? 'text' : 'password'
      },
      companionsFiltered() {
        if (this.companionFilter) {
          return this.companions.filter(companion => {
            return companion.name.toLowerCase().search(
              this.companionFilter.toLowerCase()
            ) > -1
          })  
        }

        return this.companions
      }
    }
  }
</script>